import React from 'react';
import "../styles/home.css";

const Home = () => {
  return (
    <div>
      <div className="container">
        <h1 className="main-header">Watershed Quality and Industrial Discharge Dashboard</h1>
        <p className="blue-box">
          Maintaining safe and clean drinking water is an ever-growing challenge. With the environmental industry struggling to track risk levels, 
          we will introduce WaterWise! WaterWise is a dashboard that will monitor and analyze industrial wastewater and 
          stream/lake water quality in relation to the quality of water at drinking inlets. 
          You can view graphs, data over various periods of time and post your own water quality risks within your community!
        </p>
      </div>
    </div>
  );
};

export default Home;
