// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableTitle{
    margin-top: 50px;
    margin-bottom: 15px;
}
.table-container {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    height: 80%;
}

.table-water-quality {
    width: 90%;
    border-collapse: collapse;
}

.table-header { 
    padding: 10px 20px;
}

.table-cell {
    padding: 10px 20px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/table.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".TableTitle{\n    margin-top: 50px;\n    margin-bottom: 15px;\n}\n.table-container {\n    display: flex;\n    flex-direction: column; \n    justify-content: center; \n    align-items: center; \n    height: 80%;\n}\n\n.table-water-quality {\n    width: 90%;\n    border-collapse: collapse;\n}\n\n.table-header { \n    padding: 10px 20px;\n}\n\n.table-cell {\n    padding: 10px 20px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
