import { Link } from 'react-router-dom';
import "../styles/navbar.css"
import React from 'react';
const Navbar = () => {

    return (
        <div className="Navbar">
            <nav className="Nav">

                <div className="Title">
                    <h2 className='SecondWord'>Water</h2><h2>Wise</h2>
                </div>
                
                <ul>
                    <div className="NavElements">
                        <li>
                            <a href="/#" >
                                <Link to="/">Home</Link>
                            </a>
                        </li>
                        <li>
                            <a href="/#" >
                                <Link to="/WaterQuality">WaterQuality Table</Link>
                            </a>
                        </li>
                        <li>
                            <a href="/#" >
                                <Link to="/WasteWater">WasteWater Table</Link>
                            </a>
                        </li>
                        <li>
                            <a href="/#" >
                                <Link to="/Graph">Graphs</Link>
                            </a>
                        </li>
                        <li>
                        </li>
                    </div>    
                </ul>

            </nav>
        </div>
    )
}

export default Navbar;