import React, { useEffect, useState } from 'react';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getStreamWaterData } from '../services/waterAPIService';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#a4de6c', '#d0ed57', '#ffa07a'];

const StreamWaterPieChart = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedStation, setSelectedStation] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [chartData, setChartData] = useState<any[]>([]);
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedData = await getStreamWaterData();

        if (Array.isArray(fetchedData)) {
          setData(fetchedData);

          const stationNames = Array.from(new Set(fetchedData.map(item => item.station)));
          setSelectedStation(stationNames[0] || '');

          const years = Array.from(new Set(fetchedData.map(item => item.year))).sort((a, b) => b - a);
          setAvailableYears(years);
          setSelectedYear(years[0] || null);

          updateChartData(fetchedData, stationNames[0], years[0]);
        } else {
          setError('Failed to fetch data or no data available');
        }
      } catch (err) {
        setError('Failed to fetch data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const updateChartData = (sourceData: any[], station: string, year: number) => {
    const filteredData = sourceData.filter(item => item.station === station && item.year === year);
    
    const aggregatedData = filteredData.reduce<Record<string, number>>((acc, item) => {
      if (!acc[item.parmDescription]) {
        acc[item.parmDescription] = 0;
      }
      acc[item.parmDescription] += item.result;
      return acc;
    }, {});

    const chartData = Object.entries(aggregatedData)
      .map(([name, value]) => ({
        name,
        value: Number(value.toFixed(2))
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10);

    const total = chartData.reduce((sum, item) => sum + item.value, 0);

    const finalChartData = chartData.map(item => ({
      ...item,
      percentage: ((item.value / total) * 100).toFixed(2)
    }));

    setChartData(finalChartData);
  };

  const handleStationChange = (event: SelectChangeEvent) => {
    const newStation = event.target.value;
    setSelectedStation(newStation);
    updateChartData(data, newStation, selectedYear!);
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const newYear = event.target.value as number;
    setSelectedYear(newYear);
    updateChartData(data, selectedStation, newYear);
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
        }}>
          <p>{`Parameter: ${payload[0].name}`}</p>
          <p>{`Total Result: ${payload[0].value}`}</p>
          <p>{`Percentage: ${payload[0].payload.percentage}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>An error occurred! {error}</p>}

      <Card sx={{ minWidth: 275, flexGrow: 1 }}>
        <CardContent>
          

          <div className="flex gap-4 mb-4">
            <h2>Station Code: {selectedStation}</h2>
            <FormControl sx={{ minWidth: 180, marginX: '15px', marginY:'15px'}}>
              <InputLabel id="station-select-label">Select Station</InputLabel>
              <Select
                labelId="station-select-label"
                id="station-select"
                value={selectedStation}
                label="Select Station"
                onChange={handleStationChange}
              >
                {Array.from(new Set(data.map(item => item.station))).map(station => (
                  <MenuItem key={station} value={station}>
                    {station}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
           
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percentage }) => `${name} (${percentage}%)`}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              {/* Removed Legend */}
            </PieChart>           
          </ResponsiveContainer>
          <h3>Year: {selectedYear}</h3>
          
        </CardContent>
      </Card>
    </>
  );
};

export default StreamWaterPieChart;