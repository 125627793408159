// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* header {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
} */

.main-header{
  display: block;
  width: 100%;
  font-size: 52;
  font-weight: 700;
  color: transparent;
  background-image: linear-gradient(to right , #00264D, #00498D, #0052A2, #0074c2, #0052A2, #00498D, #00264D);
  -webkit-background-clip: text;
  background-clip: text;    
  background-size: 200%;
  background-position: -200%;
  animation: animated-gradient 10s infinite alternate-reverse;      
}

@keyframes animated-gradient{
  to{
    background-position: 200%;
  }
}

.graph-header{
  display: block;
  width: 100%;
  font-size: 40;
  font-weight: 600;
}

p {
  font-size: 16px; 
  line-height: 1.6; 
  margin: 20px;
  letter-spacing: 0.4px; 
}
.blue-box {
  border: 1px solid #00498D; /* Blue border */
  padding: 15px; /* Space inside the border */
  border-radius: 5px; /* Rounded corners */
  background-color: #9bc5f3d2; /* Light blue background for contrast */
  margin: 20px 0; /* Margin for spacing */
}`, "",{"version":3,"sources":["webpack://./src/styles/text.css"],"names":[],"mappings":"AAAA;;;;;;;GAOG;;AAEH;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,2GAA2G;EAC3G,6BAA6B;EAC7B,qBAAqB;EACrB,qBAAqB;EACrB,0BAA0B;EAC1B,2DAA2D;AAC7D;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,qBAAqB;AACvB;AACA;EACE,yBAAyB,EAAE,gBAAgB;EAC3C,aAAa,EAAE,4BAA4B;EAC3C,kBAAkB,EAAE,oBAAoB;EACxC,2BAA2B,EAAE,uCAAuC;EACpE,cAAc,EAAE,uBAAuB;AACzC","sourcesContent":["/* header {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 20px;\n} */\n\n.main-header{\n  display: block;\n  width: 100%;\n  font-size: 52;\n  font-weight: 700;\n  color: transparent;\n  background-image: linear-gradient(to right , #00264D, #00498D, #0052A2, #0074c2, #0052A2, #00498D, #00264D);\n  -webkit-background-clip: text;\n  background-clip: text;    \n  background-size: 200%;\n  background-position: -200%;\n  animation: animated-gradient 10s infinite alternate-reverse;      \n}\n\n@keyframes animated-gradient{\n  to{\n    background-position: 200%;\n  }\n}\n\n.graph-header{\n  display: block;\n  width: 100%;\n  font-size: 40;\n  font-weight: 600;\n}\n\np {\n  font-size: 16px; \n  line-height: 1.6; \n  margin: 20px;\n  letter-spacing: 0.4px; \n}\n.blue-box {\n  border: 1px solid #00498D; /* Blue border */\n  padding: 15px; /* Space inside the border */\n  border-radius: 5px; /* Rounded corners */\n  background-color: #9bc5f3d2; /* Light blue background for contrast */\n  margin: 20px 0; /* Margin for spacing */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
