import React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";

const SearchBar = ({searchQuery, onQueryChange}: any) => (
     <>
      <TextField
        className="search-bar-waste-water"
        data-testid="search-work-name"
        variant="outlined"
        placeholder="Search Work Name"
        value={searchQuery}
        onChange={onQueryChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ fill: "blue" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          mb: 2,
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'gray', 
            },
            '&:hover fieldset': {
              borderColor: 'blue', 
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0000FF',
            },
            backgroundColor: '#f5f5f5',
          },
          '& .MuiInputLabel-root': {
            color: 'gray',
          },
          '& .MuiOutlinedInput-input': {
            padding: '12px', 
            color: '#333', 
          },
        }}
      />
      <IconButton type="submit" aria-label="search">
      </IconButton>
    </> 
  );
  
export default SearchBar;