// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    font-family: "Manrope", sans-serif;
    scroll-behavior: smooth;
}

a:hover{
    cursor: pointer;
}

body {
    background-color: #E0F7FA;
}`, "",{"version":3,"sources":["webpack://./src/styles/app.css"],"names":[],"mappings":"AAEA;IACI,SAAS;IACT,UAAU;IACV,kCAAkC;IAClC,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');\n\n* {\n    margin: 0;\n    padding: 0;\n    font-family: \"Manrope\", sans-serif;\n    scroll-behavior: smooth;\n}\n\na:hover{\n    cursor: pointer;\n}\n\nbody {\n    background-color: #E0F7FA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
