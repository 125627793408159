import React from 'react';

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload?.length) {
    return (
      <div style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
      }}>
          <p>{`Type: ${payload[0].payload.sampleDate}`}</p>
          <p>{`${payload[0].value} ${payload[0].payload.unitOfMeasure}\n`}</p>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;