import React from 'react';
import WaterQualityTable from './components/WaterQualityTable';
import WasteWaterTable from './components/WasteWaterTable';
import './styles/buttons.css';
import './styles/text.css'; // Ensure this file is imported
import Navbar from './components/Navbar';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import "./styles/app.css"
import Graphs from './components/Graphs';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/WaterQuality" element={<WaterQualityTable/>} />
          <Route path="//WasteWater" element={<WasteWaterTable/>} />
          <Route path="/Graph" element={<Graphs/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;