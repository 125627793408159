import React, { useEffect, useState } from "react";
import { getWaterQualityData } from "../services/waterAPIService";
import '../styles/table.css';
import '../styles/buttons.css';
import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";

interface WaterQualityData {
  id: string;
  lakeName: string;
  sampleDate: string;
  ph: number;
  conductance: number;
  alkalinity: number;
  chloride: number;
}

type Order = 'asc' | 'desc';
type OrderBy = keyof Omit<WaterQualityData, 'id'>;

const WaterQualityTable = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [databaseUpdateTrigger, setDatabaseUpdateTrigger] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<OrderBy>('lakeName');

  //Set up the headers for the columns, so in the table we can just loop over this array of objects
  const colHeaders: { id: OrderBy; label: string; numeric: boolean }[] = [
    { id: 'lakeName', label: 'Lake Name', numeric: false },
    { id: 'sampleDate', label: 'Sample Date', numeric: false },
    { id: 'ph', label: 'pH', numeric: true },
    { id: 'conductance', label: 'Conductance', numeric: true },
    { id: 'alkalinity', label: 'Alkalinity (mg/L)', numeric: true },
    { id: 'chloride', label: 'Chloride (mg/L)', numeric: true }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //determines if the user is asking for ascending or descending sort.
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = (orderBy === property && order === 'asc');
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  //This sorts the data based on what the user wants, be it date, string or number
  //This is called whenever we load the table (or update it)
  const sortData = (data: WaterQualityData[]): WaterQualityData[] => {
    return [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (orderBy === 'sampleDate') {
        return order === 'asc'
          ? new Date(aValue).getTime() - new Date(bValue).getTime()
          : new Date(bValue).getTime() - new Date(aValue).getTime();
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return order === 'asc'
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
    });
  };

  //fetches the data from the BE/DB
  useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const fetchedData = await getWaterQualityData();
          if (Array.isArray(fetchedData)) {
            setData(fetchedData);
          } else {
            setError('Failed to fetch data');
          }
        } catch (err) {
          setError('Failed to fetch data');
          console.error('Error fetching data:', err);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [databaseUpdateTrigger]);

    return (
      <>
        <h2 className="TableTitle">Water Quality Table</h2>
        
        <div className="table-container">
          {loading && <p>Loading...</p>}
          {error && (
            <Alert severity="error">
              An error occured with displaying the data: {error}
            </Alert>
          )}

          {data && data.length > 0 && ( 
          <Paper sx={{ width: '98%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 540 }}>
            <Table stickyHeader data-testid="water-quality-table">
              <TableHead>
                <TableRow>
                {colHeaders.map((header) => (
                  <TableCell 
                    key={header.id}
                    data-testid={header.id}
                    align={header.numeric ? 'right' : 'left'}
                    sx={{
                      backgroundColor: '#333333',
                      color: 'white',
                      fontWeight: 'bold',
                      '&.MuiTableCell-stickyHeader': {
                        backgroundColor: '#333333',
                      }
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={() => handleRequestSort(header.id)}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                        '&.Mui-active': {
                          color: 'white !important',
                        },
                        '&:hover': {
                          color: 'white !important',
                        },
                      }}
                    >
                      {header.label}
                    </TableSortLabel>                      
                  </TableCell>
                ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortData(data)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry) => {
                    return (
                      <TableRow key={entry.id}>
                        <TableCell align="left">{entry.lakeName}</TableCell>
                        <TableCell align="left">{entry.sampleDate}</TableCell>
                        <TableCell align="right">{entry.ph}</TableCell>
                        <TableCell align="right">{entry.conductance}</TableCell>
                        <TableCell align="right">{entry.alkalinity}</TableCell>
                        <TableCell align="right">{entry.chloride}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-testid={"water-quality-pagination-bar"}
          />
          </Paper>
          )}
          
          {data.length <= 0 && !loading && !error &&(
            <Alert severity="info">
              Currently, there is no information to display for this table. Try again later.
            </Alert>
          )}
        </div>
      </>
    );

};

export default WaterQualityTable;