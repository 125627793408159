import React, { useEffect, useState } from "react";
import { getWasteWaterData} from "../services/waterAPIService";
import '../styles/table.css';
import '../styles/buttons.css';
import Alert from "@mui/material/Alert";
import TableContainer from "@mui/material/TableContainer";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableSortLabel } from "@mui/material";
import SearchBar from "./SearchBar";

interface WasteWaterData {
  id: number;
  worksName: string;
  componentType: string;
  sampleDate: string;
  sector: string;
  parameterName: string;
  value: number;
  unitOfMeasure: string;
  frequency: string;
}

type Order = 'asc' | 'desc';
type OrderBy = keyof Omit<WasteWaterData, 'id' | 'unitOfMeasure'>;

const WasteWaterTable = () => {
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [databaseUpdateTrigger, setDatabaseUpdateTrigger] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<OrderBy>('worksName');

  const colHeaders: { id: OrderBy; label: string; numeric: boolean }[] = [
    { id: 'worksName', label: 'Work Name', numeric: false },
    { id: 'sector', label: 'Sector', numeric: false },
    { id: 'componentType', label: 'Component Type', numeric: false },
    { id: 'parameterName', label: 'Parameter Name', numeric: false },
    { id: 'value', label: 'Value', numeric: true },
    { id: 'sampleDate', label: 'Sample Date', numeric: false },
    { id: 'frequency', label: 'Frequency', numeric: false }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //determines if the user is asking for ascending or descending sort.
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //This sorts the data based on what the user wants, be it date, string or number
  //This is called whenever we load the table (or update it)
  const sortData = (data: WasteWaterData[]): WasteWaterData[] => {
    return [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (orderBy === 'sampleDate') {
        return order === 'asc'
          ? new Date(aValue).getTime() - new Date(bValue).getTime()
          : new Date(bValue).getTime() - new Date(aValue).getTime();
      }

      if (orderBy === 'value') {
        return order === 'asc'         
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
      }

      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  };

  //Updates the table when user types in the search field
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);

    if (value === '') {
      setFilteredData(data);
    } else {
      const filteredWorkNames = data.filter(d =>
        Object.values(d).some(val =>
          String(val).toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filteredWorkNames);
    }
  };

  const parseParameterName = (paraName: string): string => {
    if (!paraName) return '';
    const [mainNameSegment] = paraName.split(",", 1);
    return mainNameSegment;
  }

  //fetches the data from the BE/DB for waste water
  useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const fetchedData = await getWasteWaterData();
          if (Array.isArray(fetchedData)) {
            setData(fetchedData);
            setFilteredData(fetchedData);
          } else {
            setError('Failed to fetch notes');
          }
        } catch (err) {
          setError('Failed to fetch data');
          console.error('Error fetching data:', err);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [databaseUpdateTrigger]);

    return (
      <>
        {/* <Navbar/> */}
        <h2 className="TableTitle">Waste Water Table</h2>

        <div className="table-container">
          {loading && <p>Loading...</p>}
          {error && (
            <Alert severity="error">
              An error occured with displaying the data: {error}
            </Alert>
          )}

          {!loading && !error &&(
            <div>
              <SearchBar searchQuery={searchQuery} onQueryChange={handleSearchChange} />
            </div>
          )}

          {data && data.length > 0 && ( 
          <Paper sx={{ width: '98%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 460 }}>
            <Table stickyHeader data-testid="waste-water-table">
              <TableHead>
                <TableRow>
                 {colHeaders.map((header, index) => (
                  <TableCell 
                    key={index}
                    data-testid={header.id}
                    sx={{
                      backgroundColor: '#333333',
                      color: 'white',
                      fontWeight: 'bold',
                      '&.MuiTableCell-stickyHeader': {
                        backgroundColor: '#333333',
                      }
                    }}
                    >
                    <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => handleRequestSort(header.id)}
                        sx={{
                          '& .MuiTableSortLabel-icon': {
                            color: 'white !important',
                          },
                          '&.Mui-active': {
                            color: 'white !important',
                          },
                          '&:hover': {
                            color: 'white !important',
                          },
                        }}
                      >
                        {header.label}
                      </TableSortLabel>
                  </TableCell>
                 ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortData(filteredData)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry) => {
                    return (
                      <TableRow key={entry.id}>
                        <TableCell align="left">{entry.worksName}</TableCell>
                        <TableCell align="left">{entry.sector}</TableCell> 
                        <TableCell align="left">{entry.componentType}</TableCell>
                        <TableCell align="left">{parseParameterName(entry.parameterName)}</TableCell>
                        <TableCell align="justify">{`${entry.value} ${entry.unitOfMeasure}`}</TableCell>
                        <TableCell align="left">{entry.sampleDate}</TableCell>
                        <TableCell align="left">{entry.frequency}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250, 500, { label: 'All', value: -1 }]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-testid={"waste-water-pagination-bar"}
          />
          </Paper>      
          )}

          {data.length <= 0 && !loading && !error && (
            <Alert severity="info">
              Currently, there is no information to display for this table. Try again later.
            </Alert>
          )}
        </div>
      </>
    );

};

export default WasteWaterTable;