import React, {useEffect, useState} from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getWasteWaterData } from '../services/waterAPIService';
import CustomTooltip from './CustomTooltip';

const WasteWaterLineChart = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedWorkName, setSelectedWorkName] = useState('BRUCE POWER INC. - DEVELOPMENT SERVICES A AND B');
  const [selectedParameterName, setSelectedParameterName] = useState('AMMONIUM+AMMONIA, TOTAL   FILTER.REAC');
  const [selectedComponentType, setSelectedComponentType] = useState('Average');
  const [selectedWorkData, setSelectedWorkData] = useState<any[]>([]);
  const [availableParameters, setAvailableParameters] = useState<string[]>([]);
  const [availableComponentTypes, setAvailableComponentTypes] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const fetchedData = await getWasteWaterData();
        if (Array.isArray(fetchedData)) {
          setData(fetchedData);
          // Get parameters available for initial workName
          const initialParameters = Array.from(new Set(
            fetchedData.filter(item => item.worksName === selectedWorkName)
              .map(item => item.parameterName)
          ));
          setAvailableParameters(initialParameters);

          // Set initial parameter if default is not available
          if (!initialParameters.includes(selectedParameterName)) {
            setSelectedParameterName(initialParameters[0] || '');
          }

          // Get component types for initial workName and parameterName
          const initialComponentTypes = Array.from(new Set(
            fetchedData.filter(item => 
              item.worksName === selectedWorkName && 
              item.parameterName === (initialParameters.includes(selectedParameterName) ? selectedParameterName : initialParameters[0])
            ).map(item => item.componentType)
          ));
          setAvailableComponentTypes(initialComponentTypes);
          setSelectedComponentType(initialComponentTypes[0] || '');

          // Update selected data
          updateSelectedData(fetchedData, selectedWorkName, 
            initialParameters.includes(selectedParameterName) ? selectedParameterName : initialParameters[0],
            initialComponentTypes[0]);

        } else {
          setError('Failed to fetch data');
        }
      } catch (err) {
        setError('Failed to fetch data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const updateSelectedData = (sourceData: any[], workName: string, paramName: string, compType: string) => {
    setSelectedWorkData(sourceData.filter(item =>
      item.worksName === workName &&
      item.parameterName === paramName &&
      item.componentType === compType
    ));
  };

  // Handles dropdowns in a cascading style - if workname updates, 2 other dropdowns must also update.
  const handleWorkName = (event: SelectChangeEvent) => {
    const newWorkName = event.target.value;
    setSelectedWorkName(newWorkName);
    
    // Update available parameters for selected work
    const newParameters = Array.from(new Set(
      data.filter(item => item.worksName === newWorkName)
        .map(item => item.parameterName)
    ));
    setAvailableParameters(newParameters);
    
    // Reset parameter selection if current selection isn't available
    const newParameterName = newParameters.includes(selectedParameterName) 
      ? selectedParameterName 
      : newParameters[0] || '';
    setSelectedParameterName(newParameterName);
    
    // Update available component types
    const newComponentTypes = Array.from(new Set(
      data.filter(item => 
        item.worksName === newWorkName && 
        item.parameterName === newParameterName
      ).map(item => item.componentType)
    ));
    setAvailableComponentTypes(newComponentTypes);
    
    // Reset component type if current selection isn't available
    const newComponentType = newComponentTypes.includes(selectedComponentType)
      ? selectedComponentType
      : newComponentTypes[0] || '';
    setSelectedComponentType(newComponentType);
    
    updateSelectedData(data, newWorkName, newParameterName, newComponentType);
  };

  const handleParameterName = (event: SelectChangeEvent) => {
    const newParameterName = event.target.value;
    setSelectedParameterName(newParameterName);
    
    // Update available component types
    const newComponentTypes = Array.from(new Set(
      data.filter(item => 
        item.worksName === selectedWorkName && 
        item.parameterName === newParameterName
      ).map(item => item.componentType)
    ));
    setAvailableComponentTypes(newComponentTypes);
    
    // Reset component type if current selection isn't available
    const newComponentType = newComponentTypes.includes(selectedComponentType)
      ? selectedComponentType
      : newComponentTypes[0] || '';
    setSelectedComponentType(newComponentType);
    
    updateSelectedData(data, selectedWorkName, newParameterName, newComponentType);
  };

  const handleComponentType = (event: SelectChangeEvent) => {
    const newComponentType = event.target.value;
    setSelectedComponentType(newComponentType);
    updateSelectedData(data, selectedWorkName, selectedParameterName, newComponentType);
  };

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>An error occured! {error}</p>} 

      <Card sx={{ minWidth: 275, flexGrow: 1 }}>
        <CardContent>

          <h2>{selectedWorkName} - {selectedParameterName}</h2>

          <div className="flex gap-4 mb-4">
            <FormControl sx={{ minWidth: 180, marginX: '15px', marginY:'15px' }}>
              <InputLabel id="work-input-select-label">Select Work</InputLabel>
              <Select
                labelId="work-select-label"
                id="work-select"
                data-testid="work-select"
                value={selectedWorkName}
                label="Select Work"
                onChange={handleWorkName}
              >
                {Array.from(new Set(data.map(workData => workData.worksName))).map((worksName)  => (
                  <MenuItem key={worksName} value={worksName}>
                    {worksName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 160, marginX: '15px', marginY:'15px'}}>
              <InputLabel id="parameter-input-select-label">Select Parameter</InputLabel>
              <Select
                labelId="parameter-select-label"
                id="parameter-select"
                data-testid="parameter-select"
                value={selectedParameterName}
                label="Select Parameter"
                onChange={handleParameterName}
              >
                {availableParameters.map((paramName) => (
                  <MenuItem key={paramName} value={paramName}>
                    {paramName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120, marginX: '15px' , marginY:'15px'}}>
              <InputLabel id="component-type-input-select-label">Select Type</InputLabel>
              <Select
                labelId="component-type-select-label"
                id="component-type-select"
                data-testid="component-type-select"
                value={selectedComponentType}
                label="Select Type"
                onChange={handleComponentType}
              >
                {availableComponentTypes.map((compType) => (
                  <MenuItem key={compType} value={compType}>
                    {compType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={selectedWorkData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="sampleDate" />
              <YAxis/>
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line type="monotone" dataKey="value" name={`${selectedWorkData[0]?.parameterName} (${selectedWorkData[0]?.unitOfMeasure})`}  stroke="#7874d8" />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default WasteWaterLineChart;
