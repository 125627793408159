import React from 'react'
import WasteWaterLineChart from './WasteWaterLineChart';
import WaterQualityLineChart from './WaterQualityLineChart';
import StreamWaterPieChart from './StreamWaterQualitygraph';
import "../styles/graphs.css"

const Graphs = () => {
  return (
    <div>
        <div className='graphs'>
        <div className='wtrQualityChart'>
                <WaterQualityLineChart />
            </div>
            

            <div className='wastewtrChart'>
                <WasteWaterLineChart />
            </div>

            <div className='streamwtrChart'>
                <StreamWaterPieChart />
            </div>
        </div>
        
    </div>
  )
}

export default Graphs