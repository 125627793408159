// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar {
    height: 64px;
}

.Navbar nav {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.Navbar nav .Title {
    display: flex;
    align-items: center;
    margin-left: 200px;
}

.Navbar nav .Title h2 {
    display: inline;
    margin: 0;
}

.Navbar nav .Title .SecondWord {
    color: #0074D9;
}

.Navbar nav .NavElements {
    display: flex;
    list-style: none;
    gap: 25px; 
    margin-right: 200px;
}

.Navbar nav .NavElements li a:link,
.Navbar nav .NavElements li a:visited {
    color: black;
    text-decoration: none;
    transition: border-bottom 0.3s ease;
}

.Navbar nav .NavElements li a:hover {
    border-bottom: 2px solid #0074D9;
}

.Navbar nav .NavElements li a:active {
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,mBAAmB;AACvB;;AAEA;;IAEI,YAAY;IACZ,qBAAqB;IACrB,mCAAmC;AACvC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".Navbar {\n    height: 64px;\n}\n\n.Navbar nav {\n    display: flex;\n    justify-content: space-between;\n    align-items: center; \n    padding: 1rem 2rem;\n    background-color: white;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n}\n\n.Navbar nav .Title {\n    display: flex;\n    align-items: center;\n    margin-left: 200px;\n}\n\n.Navbar nav .Title h2 {\n    display: inline;\n    margin: 0;\n}\n\n.Navbar nav .Title .SecondWord {\n    color: #0074D9;\n}\n\n.Navbar nav .NavElements {\n    display: flex;\n    list-style: none;\n    gap: 25px; \n    margin-right: 200px;\n}\n\n.Navbar nav .NavElements li a:link,\n.Navbar nav .NavElements li a:visited {\n    color: black;\n    text-decoration: none;\n    transition: border-bottom 0.3s ease;\n}\n\n.Navbar nav .NavElements li a:hover {\n    border-bottom: 2px solid #0074D9;\n}\n\n.Navbar nav .NavElements li a:active {\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
