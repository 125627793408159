// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column; 
  padding: 20px;
  justify-content: center;
  align-items: center; 
}

.main-header {
  font-size: 2rem;
  margin-top: 20px; 
  text-align: center; 
  margin-top: 50px;
  margin-bottom: 100px; 
}

.blue-box {
  text-align: center;
  max-width: 80%; 
  margin: 0 auto; 
}
`, "",{"version":3,"sources":["webpack://./src/styles/home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column; \n  padding: 20px;\n  justify-content: center;\n  align-items: center; \n}\n\n.main-header {\n  font-size: 2rem;\n  margin-top: 20px; \n  text-align: center; \n  margin-top: 50px;\n  margin-bottom: 100px; \n}\n\n.blue-box {\n  text-align: center;\n  max-width: 80%; \n  margin: 0 auto; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
