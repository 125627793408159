import React, {useEffect, useState} from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getWaterQualityData } from '../services/waterAPIService';

const WaterQualityLineChart = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLake, setSelectedLake] = useState('Blue Chalk Lake');
  const [selectedLakeData, setSelectedLakeData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const fetchedData = await getWaterQualityData();
        if (Array.isArray(fetchedData)) {
          setData(fetchedData);
          setSelectedLakeData(fetchedData.filter(data => data.lakeName === selectedLake));
        } else {
          setError('Failed to fetch data');
        }
      } catch (err) {
        setError('Failed to fetch data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleLakeChange = (event: SelectChangeEvent) => {
    const selectedLakeName = event.target.value;
    setSelectedLake(selectedLakeName);
    setSelectedLakeData(data.filter(data => data.lakeName === selectedLakeName));
  };

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>An error occured! {error}</p>}

      <Card sx={{ minWidth: 275, flexGrow: 1 }}>
        <CardContent>
          <h2>{selectedLake} pH Levels</h2>
          
          <FormControl sx={{ minWidth: 180 , marginY:'15px'}}>
            <InputLabel id="lake-select-label">Select Lake</InputLabel>
            <Select
              labelId="lake-select-label"
              id="lake-select"
              data-testId='lake-select'
              value={selectedLake}
              label="Select Lake"
              onChange={handleLakeChange}
            >
              {Array.from(new Set(data.map(lake => lake.lakeName))).map((lakeName)  => (
                <MenuItem key={lakeName} value={lakeName}>
                  {lakeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={selectedLakeData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="sampleDate" />
              <YAxis dataKey="ph"/>
              <Tooltip />
              <Legend />
              <Line               
              type="monotone"
              dataKey="ph"
              stroke="#7874d8"
              strokeWidth={2}
              dot={{ r: 4 }}
              name="pH Level"
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default WaterQualityLineChart;
