import { WasteWaterPromise, WaterQualityPromise, StreamWaterPromise } from "../types/Promises";

const API_WATER = '/api/data';
const API_WASTE = '/api/wastewater'
const API_STREAM = '/api/stream-water-quality'
const API_PYTHON = '/python-api';
//console.log('API_URL:', API_URL);

//TODO: Get a type in here for the promise
export const getWaterQualityData = async (): Promise<WaterQualityPromise[]> => {
    console.log('Fetching all notes from:', `${API_WATER}/all`);  // Log the full endpoint
    try {
      const response = await fetch(`${API_WATER}/all`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data: WaterQualityPromise[] = await response.json();
      console.log('API response:', data); // Log the response data
      return data;
    } catch (error) {
      console.error('Error fetching notes:', error);
      return [];
    }
};

export const getWasteWaterData = async (): Promise<WasteWaterPromise[]> => {
  console.log('Fetching all notes from:', `${API_WASTE}/all`);  // Log the full endpoint
  try {
    const response = await fetch(`${API_WASTE}/all`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: WasteWaterPromise[] = await response.json();
    console.log('API response:', data); // Log the response data
    return data;
  } catch (error) {
    console.error('Error fetching notes:', error);
    return [];
  }
};

export const getStreamWaterData = async (): Promise<StreamWaterPromise[]> => {
  console.log('Fetching all notes from:', `${API_STREAM}/all`);  // Log the full endpoint
  try {
    const response = await fetch(`${API_STREAM}/all`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: StreamWaterPromise[] = await response.json();
    console.log('API response:', data); // Log the response data
    return data;
  } catch (error) {
    console.error('Error fetching notes:', error);
    return [];
  }
};

//Calls python script to retrieve water quality from the data service
export const retrieveWaterQualityDataFromDataService = async () => {
    console.log('Retrieve notes from data service from:', `${API_PYTHON}/water-quality/sync`);  // Log the full endpoint and data
    try {
      const response = await fetch(`${API_PYTHON}/water-quality/sync`, {
        method: 'POST',
      });
      if (!response.ok) { 
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data: string = await response.text();
      console.log(response)
      return data;
    } catch (error) {
      console.error('Error fetching notes from data service:', error);
      throw error;
    }
};
  
//Calls python script to retrieve waste water from the data service
export const retrieveWasteWaterDataFromDataService = async () => {
  console.log('Retrieve notes from data service from:', `${API_PYTHON}/wastewater/sync`);  // Log the full endpoint and data
  try {
    const response = await fetch(`${API_PYTHON}/wastewater/sync`, {
      method: 'POST',
    });
    if (!response.ok) { 
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: string = await response.text();
    console.log(response)
    return data;
  } catch (error) {
    console.error('Error fetching notes from data service:', error);
    throw error;
  }
};

//Calls python script to retrieve stream water from the data service
export const retrieveStreamWaterDataFromDataService = async () => {
  console.log('Retrieve notes from data service from:', `${API_PYTHON}/stream-water-quality/sync`);  // Log the full endpoint and data
  try {
    const response = await fetch(`${API_PYTHON}/stream-water-quality/sync`, {
      method: 'POST',
    });
    if (!response.ok) { 
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: string = await response.text();
    console.log(response)
    return data;
  } catch (error) {
    console.error('Error fetching notes from data service:', error);
    throw error;
  }
};
