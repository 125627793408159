import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';

// Function to start the mock service worker
async function deferRender() {
  /*
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');
    return worker.start();
  }
  // If not in development, resolve immediately
  return Promise.resolve();
  */
}

// Start the render process
deferRender().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});
