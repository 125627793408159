// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
  margin: 0;
  padding: 0;
}

.graphs {
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
  height: calc(100vh - 60px); 
  padding: 20px;
}

.wtrQualityChart,
.wastewtrChart {
  width: 100%; 
  display: flex;
  justify-content: center; 
  margin-bottom: 20px; 
}


`, "",{"version":3,"sources":["webpack://./src/styles/graphs.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;EAC1B,aAAa;AACf;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["\nbody {\n  margin: 0;\n  padding: 0;\n}\n\n.graphs {\n  display: flex;\n  flex-direction: column; \n  justify-content: space-between; \n  height: calc(100vh - 60px); \n  padding: 20px;\n}\n\n.wtrQualityChart,\n.wastewtrChart {\n  width: 100%; \n  display: flex;\n  justify-content: center; \n  margin-bottom: 20px; \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
